<template>
  <screen-grid class="leads-general">
    <screen-card
      :title="$t('leads.id.general.section.general')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('leads.id.general.label.leadId')"
          icon="attribut"
          :has-data="typeof currentLead.id === 'number'"
          :data="currentLead.id"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
        />
        <screen-block
          :title="$t('leads.id.general.label.client')"
          icon="account"
          :has-data="typeof currentLead.email === 'string'"
          :data="currentLead.email"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
        />
        <screen-block
          :title="$t('leads.id.general.label.status.customer.title')"
          icon="info"
          :has-data="typeof currentLead.customerStatus === 'string'"
          :data="currentLead.customerStatus"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
          :is-photo="true"
        >
          <template v-slot:data>
            <span class="leads-general__status" :class="[`leads-general__status--${currentLead.customerStatus}`]">
              <template
                v-if="currentLead.customerStatus === 'TMP_REASSIGN' || currentLead.customerStatus === 'REASSIGN'"
              >
                {{ $t('leads.id.general.label.status.customer.reassign') }}
              </template>
              <template
                v-else-if="
                  currentLead.customerStatus === 'TMP_WONT_REASSIGN' || currentLead.customerStatus === 'WONT_REASSIGN'
                "
              >
                {{ $t('leads.id.general.label.status.customer.noreassign') }}
              </template>
              <template v-else-if="currentLead.customerStatus === 'SATISFIED'">
                {{ $t('leads.id.general.label.status.customer.satisfied') }}
              </template>
              <template v-else-if="currentLead.customerStatus === 'UNSATISFIED'">
                {{ $t('leads.id.general.label.status.customer.unsatisfied') }}
              </template>
              <template v-else>{{ $t('leads.id.general.label.status.customer.waiting') }}</template>
            </span>
          </template>
        </screen-block>
        <screen-block
          :title="$t('leads.id.general.label.location')"
          icon="business"
          :has-data="typeof currentLead.locationName === 'string'"
          :data="currentLead.locationName"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
          class="leads-general__location"
        >
          <template v-slot:data>
            <a class="leads-general__location__link" :href="buildUrlShop(currentLead.locationId)" target="_blank">
              {{ currentLead.locationName }}
            </a>
            <br />
            <div v-if="currentLead.firstLocationName" class="leads-general__location__first-location">
              <div class="leads-general__location__label">
                {{ $t('leads.id.general.label.firstLocation') }}
              </div>
              <a
                class="leads-general__location__link"
                :href="buildUrlShop(currentLead.firstLocationId)"
                target="_blank"
              >
                {{ currentLead.firstLocationName }}
              </a>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('leads.id.general.label.product.id')"
          icon="attribut"
          :has-data="typeof currentLead.productId === 'string'"
          :data="currentLead.productId"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
          class="leads-general__product"
        />
        <screen-block
          :title="$t('leads.id.general.label.product.name')"
          icon="article"
          :has-data="typeof currentLead.productName === 'string'"
          :data="currentLead.productName"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
          class="leads-general__product"
        >
          <template v-slot:data>
            <a class="leads-general__product__link" :href="buildUrlProduct" target="_blank">
              {{ currentLead.productName }}
            </a>
          </template>
        </screen-block>
        <screen-block
          :title="$t('leads.id.general.label.update')"
          icon="publish"
          :has-data="typeof currentLead.updatedAt === 'string'"
          :data="dateUserLang(currentLead.updatedAt)"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
        />
        <screen-block
          :title="$t('leads.id.general.label.comment')"
          icon="message_outline"
          :has-data="typeof currentLead.comment === 'string'"
          :data="currentLead.comment"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
        />
      </template>
    </screen-card>
    <screen-card
      class="leads-general__comment"
      :title="$t('leads.id.general.section.form')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('leads.id.general.label.form')"
          icon="article"
          :has-data="typeof currentLead.form === 'object'"
          ratio="1-2"
          :is-editable="false"
          :is-loading="isLoading"
        >
          <template v-slot:data>
            <div
              class="leads-general__comment__item"
              v-for="(value, key, index) in currentLead.form"
              :key="'form' + index"
            >
              <span class="leads-general__comment__item__key">{{ key }} :</span>
              <br />
              <span v-html="value"></span>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('leads.id.general.label.contact')"
          icon="mail"
          :has-data="typeof currentLead.contact === 'object'"
          ratio="1-2"
          :is-editable="false"
          :is-loading="isLoading"
        >
          <template v-slot:data>
            <div
              class="leads-general__comment__item"
              v-for="(value, key, index) in currentLead.contact"
              :key="'contact' + index"
            >
              <span class="leads-general__comment__item__key">{{ key }} :</span>
              <br />
              {{ value }}
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { mapState } from 'vuex'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import { formatedDate } from '@/utils/date.util'
import { getShortLocal } from '@/utils/string.util'

export default {
  name: 'LeadGeneral',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      currentUserLocale: state => state.backoffice.currentUser.locale,
    }),
    buildUrlProduct() {
      return `//${this.currentLead.lpeUrl}/${this.currentLead.locale}/p/${this.currentLead.productId}`
    },
  },
  methods: {
    buildUrlShop(shopId) {
      return `//${this.currentLead.lpeUrl}/${this.currentLead.locale}/${shopId}`
    },
    dateUserLang(date) {
      return formatedDate(date, 'L LT', getShortLocal(this.currentUserLocale))
    },
  },
}
</script>

<style lang="scss" scoped>
.leads-general {
  &__product {
    &__id,
    &__link,
    &__name {
      margin-bottom: 5px;
    }

    &__label {
      color: $blue-cadet-light;
    }
    &__link {
      transition: all $transition-duration-default $transition-effect-default;
      word-break: break-all;
      color: inherit;
      &:hover {
        color: var(--product-color);
      }
      &__label {
        color: $blue-cadet-light;
      }
    }
  }

  &__location {
    &__first-location {
      margin-top: 5px;
      font-style: italic;
    }
    &__link {
      transition: all $transition-duration-default $transition-effect-default;
      color: inherit;
      &:hover {
        color: var(--product-color);
      }
    }
    &__label {
      color: $blue-cadet-light;
    }
  }

  &__status {
    border-radius: 50px;
    background-color: map-get($generic-color-variants, 'data');
    padding: 4px 8px;
    line-height: 25px;
    white-space: nowrap;
    color: white;
    font-size: 11px;
    font-weight: 300;

    &:before {
      margin-right: 5px;
      content: '⬤';
    }

    &--SATISFIED,
    &--REASSIGN,
    &--TMP_REASSIGN {
      background-color: map-get($generic-color-variants, 'success');
    }

    &--UNSATISFIED,
    &--WONT_REASSIGN,
    &--TMP_WONT_REASSIGN {
      background-color: map-get($generic-color-variants, 'error');
    }

    &--LEAD_CREATION {
      background-color: map-get($generic-color-variants, 'warning');
    }
  }

  &__comment {
    &__item {
      margin-bottom: 5px;
      &__key {
        text-transform: capitalize;
        color: $blue-cadet-light;
      }
    }
  }
}
</style>
